
.benchmark{
	h1.title,hr.divider,h3{
		color:#fff;
	}
	
}
.faq-puff{
	border-radius:6px;
	padding:10px;
	padding-left:1rem;
	margin-bottom:10px;
	background-color:RGB(40, 40, 40,0.6);
	border:1px solid #32b7b5;
	a{
		color:#fff;
		text-decoration: none;
	}
}
.premium-puff{
	padding:2rem;
	text-align:left;
	background-color: #2b3d51;
	color:#fff;
	
	ul{
		list-style-type: disc;
		width:100%;
		font-size:16pt;
	}
	ul.leadership-legends{
		list-style-type: none;
		margin-bottom:1rem;
		li.leadership-legend{
			float:left;
			margin-right:1rem;
			i{
				margin-right:10px;
				&.traditional{
					color:#3e6865;
				}
				&.insightful{
					color:#0a9b99;
				}
				&.opportunists{
					color:#17b7b4;
				}
				&.leader{
					color:#45c5c3;
				}
				
			}
		}
	}
}
.free-puff{
	padding:2rem;
	text-align:left;
	background-color:RGB(255,255,255,0.08);
	color:#fff;
	// opacity: 0.08;;
	ul{
		list-style-type: disc;
	}
	.free-logo{
		width: 20%;
		margin: 0 auto;
		display: block;
		margin-bottom:1rem;
	}
}
